import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What We Do?`}</h2>
    <ProjectCard title="Supply Professional Identity Cards" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Outsource your organization's ID cards needs to us. We take ownership from
  collecting details and photos to supplying finished ID cards.
    </ProjectCard>
    <ProjectCard title="High quality ID by professionals" link="" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  We take Quality seriously. Attention to detail, customer satisfaction is our
  motto.
    </ProjectCard>
    <ProjectCard title="Data Privacy" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Your ID data provided to us is processed with high security standard
    </ProjectCard>
    <ProjectCard title="We don't Sell your data" link="" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  That's a promise. Your data would be destroyed from our devices once the ID is
  supplied.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      